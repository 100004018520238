import clientService from '@/services/queries/clientQueries'
import { removeEmptyProps } from '@/services/utils'
import AvatarUploader from '@/components/Shared/AvatarUploader/AvatarUploader.vue'
import PhoneInput from '@/components/Shared/PhoneInput.vue'

export default {
  components: {
    AvatarUploader,
    PhoneInput
  },

  data() {
    return {
      formData: {
        email: '',
        notes: '',
        profile: {
          full_name: '',
          height: '',
          gender: 'female',
          phone_number: null,
          phone_number_country_code: 'ru',
        }
      },

      avatarData: null,

      selectedFigureType: null,
      selectedGender: null,
      selectedClothingSizes: [],

      genders: this.$t('genders'),

      phoneNumberValidationRules: {
        valid_phone: null,
        phonenumber_unique: null
      },

      loading: false
    }
  },

  metaInfo() {
    return {
      title: this.$i18n.t('agent.clients.new.pageTitle')
    }
  },

  methods: {
    onCountryChange(countryData) {
      this.phoneNumberValidationRules = {
        valid_phone: countryData.iso2,
        phonenumber_unique: countryData.iso2,
      }

      this.formData.profile.phone_number_country_code = countryData.iso2
    },

    addClient() {
      this.loading = true

      let promises = []

      Object.values(this.$refs).forEach(ref => {
        promises.push(ref.validate())
      })

      Promise.all(promises).then(results => {
        let notValid = results.some((success) => {
          return !success
        })

        if (notValid) {
          this.loading = false

          return
        }

        let data = JSON.stringify(this.getFilteredFormData())

        clientService.store(data).then(response => {
          if (!this.avatarData) {
            this.$router.push({ name: 'agent.clients' })

            return
          }

          clientService.storeAvatar(response.client_id, this.avatarData).then(() => {
            this.$router.push({ name: 'agent.clients' })
          })
        })
      })
    },

    getFilteredFormData() {
      let filteredFormData = removeEmptyProps(this.formData)

      if (this.selectedClothingSizes.length > 0) {
        filteredFormData.profile.clothing_sizes = []
        this.selectedClothingSizes.forEach((clothingSize) => {
          filteredFormData.profile.clothing_sizes.push(clothingSize.clothing_size_id)
        })
      }

      if (this.selectedGender) {
        filteredFormData.profile.gender = this.selectedGender.value
      }

      if (this.selectedFigureType) {
        filteredFormData.profile.figure_type_id = this.selectedFigureType.figure_type_id
      }

      return filteredFormData
    },

    onSelectClothingSize(value) {
      this.selectedClothingSizes.forEach((clothingSize, index) => {
        if (clothingSize.clothing_size_metric_id === value.clothing_size_metric_id) {
          this.selectedClothingSizes.splice(index, 1);
        }
      })

      this.selectedClothingSizes.push(value)
    },

    updateAvatar(avatarData) {
      this.avatarData = avatarData
    }
  },

  computed: {
    filteredFigureTypes() {
      return this.$store.state.handbooks.figureTypes.filter((figureType) => {
        return figureType.gender.toLowerCase() === this.selectedGender.value.toLowerCase()
      })
    },

    filteredClothingSizeMetrics() {
      return this.$store.state.handbooks.clothingSizeMetrics.filter((clothingSizeMetric) => {
        return clothingSizeMetric.gender.toLowerCase() === this.selectedGender.value.toLowerCase()
      })
    }
  }
}
